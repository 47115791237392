export const useAccountStore = defineStore('account', () => {
  type User = Awaited<ReturnType<typeof fetchUser>>
  const user: Ref<User | undefined> = ref()

  const fetchUser = async () => {
    const data = await $fetch('/api/users/me/', {
      method: 'GET',
    })

    user.value = data
    return data
  }

  const updateUser = async (data: Partial<User>) => {
    const result = await $fetch('/api/users/me/', {
      method: 'PATCH',
      body: data,
    })

    if (result.status === 200) {
      await fetchUser()
    }
  }

  const reset = () => {
    user.value = undefined
  }

  return {
    user,
    fetchUser,
    updateUser,
    reset,
  }
}, { persist: true })
