export default {
  "cgbank-contact": () => import("/app/layouts/cgbank/contact.vue"),
  "cgbank-default": () => import("/app/layouts/cgbank/default.vue"),
  "cgbank-footer": () => import("/app/layouts/cgbank/footer.vue"),
  "cgbank-header": () => import("/app/layouts/cgbank/header.vue"),
  "cgbank-lp": () => import("/app/layouts/cgbank/lp.vue"),
  "cgbank-navs": () => import("/app/layouts/cgbank/navs.vue"),
  "cgbank-scroll-top-btn": () => import("/app/layouts/cgbank/scroll-top-btn.vue"),
  "cgbank-tag-search": () => import("/app/layouts/cgbank/tag-search.vue"),
  dashboard: () => import("/app/layouts/dashboard.vue"),
  default: () => import("/app/layouts/default.vue"),
  lp: () => import("/app/layouts/lp.vue")
}