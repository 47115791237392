import { default as indexi8nCATZISJMeta } from "/app/pages/admin/index.vue?macro=true";
import { default as indexRtuELrsmeHMeta } from "/app/pages/admin/users/[id]/index.vue?macro=true";
import { default as indexYm9dzWmM2AMeta } from "/app/pages/admin/users/index.vue?macro=true";
import { default as artifactshuesF2vXbtMeta } from "/app/pages/artifacts.vue?macro=true";
import { default as indexUgoFhRBMnvMeta } from "/app/pages/cgbank/artifacts/index.vue?macro=true";
import { default as indexIYpPnorcecMeta } from "/app/pages/cgbank/consent/index.vue?macro=true";
import { default as demo_45artifact_45dialogJeVmzZUlftMeta } from "/app/pages/cgbank/demo/demo-artifact-dialog.vue?macro=true";
import { default as demo_45artifacts8d3OcjOfLhMeta } from "/app/pages/cgbank/demo/demo-artifacts.ts?macro=true";
import { default as indexgWAtgEfYFdMeta } from "/app/pages/cgbank/demo/index.vue?macro=true";
import { default as indexzjyxO2Jt0gMeta } from "/app/pages/cgbank/favorites/index.vue?macro=true";
import { default as collectionStq1KmcevkMeta } from "/app/pages/cgbank/index/collection.vue?macro=true";
import { default as hero_45cardHxAzMYXSerMeta } from "/app/pages/cgbank/index/hero-card.vue?macro=true";
import { default as hero_45linkowZ4Gmp2zgMeta } from "/app/pages/cgbank/index/hero-link.vue?macro=true";
import { default as hero_45servicehucrOHUuaRMeta } from "/app/pages/cgbank/index/hero-service.vue?macro=true";
import { default as heroKa47GHVeVNMeta } from "/app/pages/cgbank/index/hero.vue?macro=true";
import { default as indexBna2p4FRsCMeta } from "/app/pages/cgbank/index/index.vue?macro=true";
import { default as indexbrJUQK5vsgMeta } from "/app/pages/cgbank/pricing/index.vue?macro=true";
import { default as privacylxwrsXig9kMeta } from "/app/pages/cgbank/privacy.vue?macro=true";
import { default as indexj6KOjN0eY7Meta } from "/app/pages/cgbank/profile/index.vue?macro=true";
import { default as sctl1rc4kGfDkVMeta } from "/app/pages/cgbank/sctl.vue?macro=true";
import { default as cancelI0wv9p30G9Meta } from "/app/pages/cgbank/subscriptions/cancel.vue?macro=true";
import { default as termkMve0MP779Meta } from "/app/pages/cgbank/term.vue?macro=true";
import { default as indexz9XY3AJyPiMeta } from "/app/pages/cgbank/verify/index.vue?macro=true";
import { default as consentGvS2D7CHlRMeta } from "/app/pages/consent.vue?macro=true";
import { default as generatechsxkLJ4zPMeta } from "/app/pages/generate.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexwJEmXChT2GMeta } from "/app/pages/maintenance/index.vue?macro=true";
import { default as privacyg3gwBRGXatMeta } from "/app/pages/privacy.vue?macro=true";
import { default as indexaWicQcqNwUMeta } from "/app/pages/registration/index.vue?macro=true";
import { default as sctl3jUrz3azvbMeta } from "/app/pages/sctl.vue?macro=true";
import { default as settingsLwEYOlkQxrMeta } from "/app/pages/settings.vue?macro=true";
import { default as cancel1CoPVSKpueMeta } from "/app/pages/subscriptions/cancel.vue?macro=true";
import { default as successrVysLx7SdsMeta } from "/app/pages/subscriptions/success.vue?macro=true";
import { default as termJlyDIwxnBpMeta } from "/app/pages/term.vue?macro=true";
import { default as usageszltKNNFBFkMeta } from "/app/pages/usages.vue?macro=true";
import { default as verifyuQf3dnBI8DMeta } from "/app/pages/verify.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    meta: indexi8nCATZISJMeta || {},
    component: () => import("/app/pages/admin/index.vue")
  },
  {
    name: "admin-users-id",
    path: "/admin/users/:id()",
    meta: indexRtuELrsmeHMeta || {},
    component: () => import("/app/pages/admin/users/[id]/index.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    meta: indexYm9dzWmM2AMeta || {},
    component: () => import("/app/pages/admin/users/index.vue")
  },
  {
    name: "artifacts",
    path: "/artifacts",
    component: () => import("/app/pages/artifacts.vue")
  },
  {
    name: "cgbank-artifacts",
    path: "/cgbank/artifacts",
    meta: indexUgoFhRBMnvMeta || {},
    component: () => import("/app/pages/cgbank/artifacts/index.vue")
  },
  {
    name: "cgbank-consent",
    path: "/cgbank/consent",
    meta: indexIYpPnorcecMeta || {},
    component: () => import("/app/pages/cgbank/consent/index.vue")
  },
  {
    name: "cgbank-demo-demo-artifact-dialog",
    path: "/cgbank/demo/demo-artifact-dialog",
    component: () => import("/app/pages/cgbank/demo/demo-artifact-dialog.vue")
  },
  {
    name: "cgbank-demo-demo-artifacts",
    path: "/cgbank/demo/demo-artifacts",
    component: () => import("/app/pages/cgbank/demo/demo-artifacts.ts")
  },
  {
    name: "cgbank-demo",
    path: "/cgbank/demo",
    meta: indexgWAtgEfYFdMeta || {},
    component: () => import("/app/pages/cgbank/demo/index.vue")
  },
  {
    name: "cgbank-favorites",
    path: "/cgbank/favorites",
    meta: indexzjyxO2Jt0gMeta || {},
    component: () => import("/app/pages/cgbank/favorites/index.vue")
  },
  {
    name: "cgbank-index-collection",
    path: "/cgbank/collection",
    component: () => import("/app/pages/cgbank/index/collection.vue")
  },
  {
    name: "cgbank-index-hero-card",
    path: "/cgbank/hero-card",
    component: () => import("/app/pages/cgbank/index/hero-card.vue")
  },
  {
    name: "cgbank-index-hero-link",
    path: "/cgbank/hero-link",
    component: () => import("/app/pages/cgbank/index/hero-link.vue")
  },
  {
    name: "cgbank-index-hero-service",
    path: "/cgbank/hero-service",
    component: () => import("/app/pages/cgbank/index/hero-service.vue")
  },
  {
    name: "cgbank-index-hero",
    path: "/cgbank/hero",
    component: () => import("/app/pages/cgbank/index/hero.vue")
  },
  {
    name: "cgbank-index",
    path: "/cgbank",
    meta: indexBna2p4FRsCMeta || {},
    component: () => import("/app/pages/cgbank/index/index.vue")
  },
  {
    name: "cgbank-pricing",
    path: "/cgbank/pricing",
    meta: indexbrJUQK5vsgMeta || {},
    component: () => import("/app/pages/cgbank/pricing/index.vue")
  },
  {
    name: "cgbank-privacy",
    path: "/cgbank/privacy",
    meta: privacylxwrsXig9kMeta || {},
    component: () => import("/app/pages/cgbank/privacy.vue")
  },
  {
    name: "cgbank-profile",
    path: "/cgbank/profile",
    meta: indexj6KOjN0eY7Meta || {},
    component: () => import("/app/pages/cgbank/profile/index.vue")
  },
  {
    name: "cgbank-sctl",
    path: "/cgbank/sctl",
    meta: sctl1rc4kGfDkVMeta || {},
    component: () => import("/app/pages/cgbank/sctl.vue")
  },
  {
    name: "cgbank-subscriptions-cancel",
    path: "/cgbank/subscriptions/cancel",
    meta: cancelI0wv9p30G9Meta || {},
    component: () => import("/app/pages/cgbank/subscriptions/cancel.vue")
  },
  {
    name: "cgbank-term",
    path: "/cgbank/term",
    meta: termkMve0MP779Meta || {},
    component: () => import("/app/pages/cgbank/term.vue")
  },
  {
    name: "cgbank-verify",
    path: "/cgbank/verify",
    meta: indexz9XY3AJyPiMeta || {},
    component: () => import("/app/pages/cgbank/verify/index.vue")
  },
  {
    name: "consent",
    path: "/consent",
    component: () => import("/app/pages/consent.vue")
  },
  {
    name: "generate",
    path: "/generate",
    component: () => import("/app/pages/generate.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import("/app/pages/maintenance/index.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyg3gwBRGXatMeta || {},
    component: () => import("/app/pages/privacy.vue")
  },
  {
    name: "registration",
    path: "/registration",
    component: () => import("/app/pages/registration/index.vue")
  },
  {
    name: "sctl",
    path: "/sctl",
    meta: sctl3jUrz3azvbMeta || {},
    component: () => import("/app/pages/sctl.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/app/pages/settings.vue")
  },
  {
    name: "subscriptions-cancel",
    path: "/subscriptions/cancel",
    component: () => import("/app/pages/subscriptions/cancel.vue")
  },
  {
    name: "subscriptions-success",
    path: "/subscriptions/success",
    component: () => import("/app/pages/subscriptions/success.vue")
  },
  {
    name: "term",
    path: "/term",
    meta: termJlyDIwxnBpMeta || {},
    component: () => import("/app/pages/term.vue")
  },
  {
    name: "usages",
    path: "/usages",
    component: () => import("/app/pages/usages.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/app/pages/verify.vue")
  }
]