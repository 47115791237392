export default defineNuxtRouteMiddleware(async (to) => {
  const allowedPaths = ['/', '/privacy', '/term', '/sctl', '/cgbank', '/cgbank/sctl', '/cgbank/privacy', '/cgbank/term', '/cgbank/demo']
  if (allowedPaths.includes(to.path)) { return }

  const accountStore = useAccountStore()
  const { fetchUser } = accountStore
  const user = computed(() => accountStore.user)
  if (!user.value) { await fetchUser() }

  const router = useRouter()
  const requestURL = useRequestURL()

  // cgbankからのアクセス
  const isCGBank = requestURL.host.includes('cgbank') || to.path.includes('cgbank')
  // 規約関係のパス以外からのアクセス
  const isNotAdminPath = !['/verify', '/admin', '/consent'].includes(to.path)

  const redirectTo = async (path: string) => {
    if (path !== to.path) {
      await router.push(path)
    }
  }

  const needsVerification = !user.value?.isEmailVerified
  const needsConsent = (isCGBank: boolean) => {
    return isCGBank ? !user.value?.isCbTermAgreed : !user.value?.isTermAgreed
  }

  const needsProfileCompletion = async () => {
    await fetchUser()
    const { firstName, lastName, companyName, phoneNumber } = user.value!
    return [firstName, lastName, companyName, phoneNumber].some(value => !value)
  }

  const needsCaching = user.value?.cgBankStatus !== 'ENABLED'

  const handleProfileRedirection = async () => {
    if (needsVerification) {
      return await redirectTo(`${isCGBank ? '/cgbank' : ''}/verify`)
    }

    if (await needsProfileCompletion() && isCGBank && needsCaching && to.path === '/cgbank/pricing') {
      return redirectTo('/cgbank/demo')
    }

    if (needsConsent(isCGBank)) {
      return await redirectTo(`${isCGBank ? '/cgbank' : ''}/consent`)
    }

    if (await needsProfileCompletion()) {
      return await redirectTo(`${isCGBank ? '/cgbank/profile' : '/registration'}`)
    }

    if (isCGBank && needsCaching) {
      return await redirectTo('/cgbank/pricing')
    }

    if (to.path === '/consent' && user.value?.isTermAgreed) {
      return await redirectTo(isCGBank ? '/cgbank/artifacts' : '/generate')
    }

    if (to.path === '/verify' && user.value?.isEmailVerified) {
      return await redirectTo(isCGBank ? '/cgbank/artifacts' : '/generate')
    }

    return false
  }

  if (
    !allowedPaths.includes(to.path)
    && isNotAdminPath
  ) {
    const redirectHappened = await handleProfileRedirection()
    if (redirectHappened) { return }

    if (isCGBank && !needsCaching && user.value.cgBankStatus === 'DISABLED' && user.value.isEmailVerified && user.value.isCbTermAgreed) {
      const response = await $fetch('/api/cgbank/stripe/subscriptions', {
        method: 'POST',
        body: { test: 1 },
      })
      const url = response?.message
      if (url) {
        window.location.href = url
        return
      }
    }
  }

  await fetchUser()
})
