import { defineNuxtPlugin } from '#app/nuxt'
import { LazyDialog, LazyDownloadBtn, LazyHeader, LazyImage, LazyNavigations, LazyPopup, LazyArtifactDeleteBtn, LazyArtifactRemoveDialog, LazyArtifactDetail, LazyCgbankLogo, LazyDownload, LazySubscriptionChange, LazyHeadMeta, LazyMyRendererLogo, LazyLoraCsvUpload, LazyGenerateHistory, LazyUsageStatus, LazyArtifactSearch, LazyArtifactDialog, LazyArtifactImage, LazyCbBtn, LazyCbContainer, LazyCheckboxTags, LazyDirectusUploadCsv, LazyHashtag, LazyIconMarker, LazyIconNonePrice, LazyPrice, LazySignUpDialog, LazyTagList, LazyTag, LazyGenerateContainer, LazyGeneratedImageBar, LazyHistoryBar, LazyInputBar, LazyHistory, LazyGeneratedImage, LazyImageTagList, LazyProgress, LazySlideBeforeAfter, LazyAccessibility, LazyFreeWord, LazyFreeWordInput, LazyGenerateBtn, LazyLineDetailSelector, LazyPullDownWithImage, LazySelectorTitle, LazySimplePullDown, LazyStepContainer, LazyStepTooltip, LazyStructureTypeSelector, LazyUploadImage, LazyAlertArea, LazyBtn, LazyCatchSection, LazyContactSection, LazyContainer, LazyFooter, LazyHamburgerMenu, LazyHeroSection, LazyLpHeader, LazyPriceSection, LazyRenderingExamplesSection, LazySolutionSection, LazyVideoSection, LazyDownloadFlowSection, LazyStepOne, LazyStepThree, LazyStepTwo, LazyTriangle, LazyBaseFaq, LazyFaqSection, LazyCard, LazyEasy, LazyFeatureSection, LazyFeatureSectionV2, LazyPerspective, LazyRealtime, LazyReform, LazyTool, LazyVisual, LazySubscription, LazyTabContainer, LazyUser } from '#components'
const lazyGlobalComponents = [
  ["Dialog", LazyDialog],
["DownloadBtn", LazyDownloadBtn],
["Header", LazyHeader],
["Image", LazyImage],
["Navigations", LazyNavigations],
["Popup", LazyPopup],
["ArtifactDeleteBtn", LazyArtifactDeleteBtn],
["ArtifactRemoveDialog", LazyArtifactRemoveDialog],
["ArtifactDetail", LazyArtifactDetail],
["CgbankLogo", LazyCgbankLogo],
["Download", LazyDownload],
["SubscriptionChange", LazySubscriptionChange],
["HeadMeta", LazyHeadMeta],
["MyRendererLogo", LazyMyRendererLogo],
["LoraCsvUpload", LazyLoraCsvUpload],
["GenerateHistory", LazyGenerateHistory],
["UsageStatus", LazyUsageStatus],
["ArtifactSearch", LazyArtifactSearch],
["ArtifactDialog", LazyArtifactDialog],
["ArtifactImage", LazyArtifactImage],
["CbBtn", LazyCbBtn],
["CbContainer", LazyCbContainer],
["CheckboxTags", LazyCheckboxTags],
["DirectusUploadCsv", LazyDirectusUploadCsv],
["Hashtag", LazyHashtag],
["IconMarker", LazyIconMarker],
["IconNonePrice", LazyIconNonePrice],
["Price", LazyPrice],
["SignUpDialog", LazySignUpDialog],
["TagList", LazyTagList],
["Tag", LazyTag],
["GenerateContainer", LazyGenerateContainer],
["GeneratedImageBar", LazyGeneratedImageBar],
["HistoryBar", LazyHistoryBar],
["InputBar", LazyInputBar],
["History", LazyHistory],
["GeneratedImage", LazyGeneratedImage],
["ImageTagList", LazyImageTagList],
["Progress", LazyProgress],
["SlideBeforeAfter", LazySlideBeforeAfter],
["Accessibility", LazyAccessibility],
["FreeWord", LazyFreeWord],
["FreeWordInput", LazyFreeWordInput],
["GenerateBtn", LazyGenerateBtn],
["LineDetailSelector", LazyLineDetailSelector],
["PullDownWithImage", LazyPullDownWithImage],
["SelectorTitle", LazySelectorTitle],
["SimplePullDown", LazySimplePullDown],
["StepContainer", LazyStepContainer],
["StepTooltip", LazyStepTooltip],
["StructureTypeSelector", LazyStructureTypeSelector],
["UploadImage", LazyUploadImage],
["AlertArea", LazyAlertArea],
["Btn", LazyBtn],
["CatchSection", LazyCatchSection],
["ContactSection", LazyContactSection],
["Container", LazyContainer],
["Footer", LazyFooter],
["HamburgerMenu", LazyHamburgerMenu],
["HeroSection", LazyHeroSection],
["LpHeader", LazyLpHeader],
["PriceSection", LazyPriceSection],
["RenderingExamplesSection", LazyRenderingExamplesSection],
["SolutionSection", LazySolutionSection],
["VideoSection", LazyVideoSection],
["DownloadFlowSection", LazyDownloadFlowSection],
["StepOne", LazyStepOne],
["StepThree", LazyStepThree],
["StepTwo", LazyStepTwo],
["Triangle", LazyTriangle],
["BaseFaq", LazyBaseFaq],
["FaqSection", LazyFaqSection],
["Card", LazyCard],
["Easy", LazyEasy],
["FeatureSection", LazyFeatureSection],
["FeatureSectionV2", LazyFeatureSectionV2],
["Perspective", LazyPerspective],
["Realtime", LazyRealtime],
["Reform", LazyReform],
["Tool", LazyTool],
["Visual", LazyVisual],
["Subscription", LazySubscription],
["TabContainer", LazyTabContainer],
["User", LazyUser],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
