import revive_payload_client_QT2dEBEe0d from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@planetscale+database@1.19.0_@types+node@22.9.0_drizzle-o_ji2ya64swtucyew7mw4umee6ka/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_uidG6bGXv8 from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@planetscale+database@1.19.0_@types+node@22.9.0_drizzle-o_ji2ya64swtucyew7mw4umee6ka/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_F99Qq1UQbK from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@planetscale+database@1.19.0_@types+node@22.9.0_drizzle-o_ji2ya64swtucyew7mw4umee6ka/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_OcNcpyetsB from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@planetscale+database@1.19.0_@types+node@22.9.0_drizzle-o_ji2ya64swtucyew7mw4umee6ka/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_FD2wT5Ehcr from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@planetscale+database@1.19.0_@types+node@22.9.0_drizzle-o_ji2ya64swtucyew7mw4umee6ka/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_u6gwTdYbNW from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@planetscale+database@1.19.0_@types+node@22.9.0_drizzle-o_ji2ya64swtucyew7mw4umee6ka/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_zyY0YrC93d from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@planetscale+database@1.19.0_@types+node@22.9.0_drizzle-o_ji2ya64swtucyew7mw4umee6ka/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_pfZLgywtdQ from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.26.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_9LSgAJDRad from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@planetscale+database@1.19.0_@types+node@22.9.0_drizzle-o_ji2ya64swtucyew7mw4umee6ka/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_S4Isno8h1n from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.14.159_@parcel+watcher@2.5.0_@planetsc_y3pungkjwdy327vtib5gpfqufy/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_rNFkxbVKY7 from "/app/node_modules/.pnpm/nuxt-papa-parse@1.0.8_magicast@0.3.5_rollup@4.26.0/node_modules/nuxt-papa-parse/dist/runtime/plugin.mjs";
import plugin_niomL1ltso from "/app/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.26.0_typesc_pityk3jbljlssgrxa3qiahq4ji/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import apexchart_gUgipXIQGn from "/app/plugins/apexchart.ts";
import auth_vT9JWWT9pN from "/app/plugins/auth.ts";
import clickOutsideDirective_JuSoe6vGeC from "/app/plugins/clickOutsideDirective.ts";
import sentry_3AyO8nEfhE from "/app/plugins/sentry.ts";
import vuetify_7h9QAQEssH from "/app/plugins/vuetify.ts";
export default [
  revive_payload_client_QT2dEBEe0d,
  unhead_uidG6bGXv8,
  router_F99Qq1UQbK,
  payload_client_OcNcpyetsB,
  navigation_repaint_client_FD2wT5Ehcr,
  check_outdated_build_client_u6gwTdYbNW,
  chunk_reload_client_zyY0YrC93d,
  plugin_vue3_pfZLgywtdQ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_9LSgAJDRad,
  plugin_S4Isno8h1n,
  plugin_rNFkxbVKY7,
  plugin_niomL1ltso,
  apexchart_gUgipXIQGn,
  auth_vT9JWWT9pN,
  clickOutsideDirective_JuSoe6vGeC,
  sentry_3AyO8nEfhE,
  vuetify_7h9QAQEssH
]